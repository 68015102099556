/* You can add global styles to this file, and also import other style files */


$primary: #eb8c00;
$primary-color: #eb8c00;
$logout: #ffa929;
$dark-gray: #999;
$gray: #aaa;

@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "~@angular/cdk/overlay-prebuilt.css";
@import "~@ak4a/styles/styles/themes/default-theme";

* {
  font-family: "Helvetica Neue Custom",sans-serif;
}

  .wide-row {
    margin-left: -25px;
    margin-right: -25px;
  }

  .apk-header.apk-header-sm-nav .apk-main-nav{
    padding-top: 0px;
  }

  .list {
    border: none;
  }

  .center-row {
    justify-content: center;
  }

.w100 {
  width: 100%!important;
}

.wizard-navigation-bar {
	display: none !important;
}

.entityDialog .mat-dialog-container {
  width: 1000px;
  padding: 0px;
}

.p-10{
  padding: 10px;
}

.p-b-20{
  padding-bottom : 20px;
}

.nav-template.template-theme-one .left-nav.op-nav {
  width: 11.625rem!important;
}

.nav-template.template-theme-one .left-nav.op-nav .item .item-content {
  padding-left: 1.875rem;
  padding-right: 0!important;
}

body {
  background-color: #f5f5f5;
}

.apk-card .apk-card-header {
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.apk-accordion {
  padding-bottom: 1rem;
}

.mat-expansion-panel-header {
  height: 48px !important;
}

.apk-accordion .mat-expansion-panel {
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 1px;
  color: #2d2d2d;
  border-radius: 2px;
  border-bottom: 1px solid #666666;
}

.apk-accordion .mat-expansion-panel .mat-expansion-indicator:after {
  padding: 8px;
  vertical-align: baseline;
  color: #d04a02;
}
.apk-accordion .mat-expansion-panel-header-title {
  font-size: 1.25rem;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.composeBtn {
  font-size: 0.8rem;
  color: #d04a02;
  cursor: pointer;
  padding-bottom: 3rem;
}

.required {
  color: red;
}

.apk-radio .mat-radio-button {display: inline-block}
.apk-radio .mat-radio-button .mat-radio-label { padding : 0px 10px 0px 0px }